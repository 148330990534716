import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const LegalPermission = () => import('@/entities/legal-permission/legal-permission.vue');
const LegalPermissionUpdate = () => import('@/entities/legal-permission/legal-permission-update.vue');
const LegalPermissionDetails = () => import('@/entities/legal-permission/legal-permission-details.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'legal-permission',
      name: 'LegalPermission',
      component: LegalPermission,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'legal-permission/new',
      name: 'LegalPermissionCreate',
      component: LegalPermissionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'legal-permission/:legalPermissionId/edit',
      name: 'LegalPermissionEdit',
      component: LegalPermissionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'legal-permission/:legalPermissionId/view',
      name: 'LegalPermissionView',
      component: LegalPermissionDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
